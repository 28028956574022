.dropdownbut{
    z-index: 99 !important;
    position: absolute;
    cursor: pointer;
    top: 2.5rem;
    right: 8.7rem;
    width: fit-content;
    padding: 15px;
    border-radius: 15px;
    background-color: white;
    z-index: 99999;
}

.dropdownbut:before{
    z-index: 99;
    position: absolute;
    content: '';
    top: -0.7rem;
    right: 2rem;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background-color: white;
    border-left: 1px solid grey;
    border-top: 1px solid grey;
}
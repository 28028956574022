.news-title{
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both, fadeIn 1s;
}
.news-container{
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    background-color: #f8f8f8;
}
.news-grid{
    display: grid;
    align-items: center;
    grid-template-columns: 30% 30% 30%;
    padding: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.news-card{
    padding: 30px;
    background-color: #ececec;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    height: 400px;
}

@media only screen and (max-width: 730px) {
    .news-grid{
        display: block;
        align-items: center;
        grid-template-columns: 30% 30% 30%;
        padding: 20px;
        gap: 20px;
        justify-content: center;
        align-items: center;
    }
    .news-grid p {
        font-size: 10px;
    }
}

.contact-title{
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both, fadeIn 1s;
}

@media only screen and (max-width: 730px) {
    .about-info{
        display: block;
        width: fit-content;
    }

    .map{
        width: auto;
    }
}
.footer-all{
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    height: fit-content;
}
.footer{
    background-color: #528be6;
    color: rgb(236, 236, 236);
}
.footer-under{
    background-color: #264474;
    color: rgb(236, 236, 236);
}

.footer-menu{
    width: fit-content;
}

.footer-menu a:hover{
    color: #8cffa9 !important;
}


@media only screen and (max-width: 730px) {
    .footer-row{
        display: block;
    }
    .footer{
        height: 400px;
    }
    .footer-menu{
        margin-top: 20px;
        margin-left: 20px;
    }
    .footer-menu h2,
    .footer-menu a{
        font-size: 12px;
    }
    .footer-all p{
        font-size: 10px;
        width: fit-content;
        padding: 10px;
    }
    .footer-under{
        height: 450px;
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
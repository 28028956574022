.mobile-nav{
    display: none;
}
.menu-mobile{
    display: none;
    visibility: hidden;
}
@media only screen and (max-width: 770px) {
    .logo img{
        height: 4rem;
    }
}

@media only screen and (max-width: 730px) {
    .social,
    .selections{
        display: none;
        visibility:hidden;
    }
    .selections{
        display: none;
        visibility:hidden;
    }
    .social-out {
        right: 5px;
    }
    .mobile-nav{
        display: block;
    }
    .logo{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .mobile-nav{
        position: sticky;
    }
    .menu-mobile{
        display: block;
        visibility: visible;
    }
    .menu-mobile-inner{
        border-radius: 50px;
        gap: 10px;
    }    
    .menu-mobile-inner img{
        border-radius: 100%;
        fill: white !important;
        color: aliceblue;
        width: 45px;
        height: 70%;
    }
    
}
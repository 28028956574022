.partners-title{
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both, fadeIn 1s;
}

.grid-partner{
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
    padding: 10px;
    width: 70%;
}

@media only screen and (max-width: 730px) {
    .grid-partner{
        display: block; 
        padding: 10px;
        width: 70%;
    }
    .partner-card{
        margin-top: 10px;
    }
}
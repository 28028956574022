.home{
    height: 100%;
}
.menu{
    min-height: 500px;
    width: 98%;
    border-radius: 15px;
    margin: auto;
    position: relative;
    align-items: center;
    justify-content: center;
}
.title{
    color: black !important;
}
.blur-background {
    background-color: #5FD5F3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../resources/images/bg.jpg');
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(0px); /* Adjust the blur amount as needed */
    z-index: -1; /* Place the blurred background behind the content */
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .logo-extra{
    height: 50px;
  }
.container{
    width: 70%;
    height: 100%;
    text-align: left;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both, fadeIn 1s;
}
.container h1{
    font-size: 60px;
    font-weight: bold;
    color: white;
    border-radius: 8px;
}
.container a{
    padding: 15px;
    background-color: #3663AC;
    color: rgb(255, 255, 255);
    font-weight: bold;
    border-radius: 3px;
}

.project-info{
    margin-top: 30px;
    margin-bottom: 30px;
    border-color: rgb(233, 196, 148);
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}   
.info-box{
    width: 60%;
    background-color: rgb(245, 245, 245);
    padding: 20px;
}
.box-line{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    gap: 25px;
}

.box-line img{
    height: 220px;
}
.box-line h2{
    font-weight: bold;
    font-size: 30px;
}
#vision{
    color: rgb(58, 161, 58);
}
#mission{
    color: rgb(35, 56, 94);
}
.box-line p{
    text-align: justify;
    font-size: 14px;
}
.box-line ul li{
    text-align: left;
    text-decoration: dotted;
    font-size: 14px;
}
.box-line h2, p{
    text-align: left;
}
#results{
    color: #3663AC;
    font-weight: bold;
    font-size: 30px;
}

.grid-results {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
    gap: 10px;
  }


@media only screen and (max-width: 730px) {
    .grid-results {
        display: block;
        padding: 5px;
        font-size: 12px;
      }
      .grid-results p{
        font-size: 11px;
      }
      .grid-result{
        margin-bottom: 12px;
      }
    .box-line{
        width: 100%;
        display: block;
        gap: 15px;
    }
    .menu{
        display: none;
    }
    .info-box{
        width: 90%;
        background-color: rgb(245, 245, 245);
        padding: 20px;
    }
}

.grid-result {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-align: left;
    gap: 10px;
}
.grid-result p{
    font-size: 12px;
}
.icon-container{
    background-color: #36ac67;
    padding: 20px;
}
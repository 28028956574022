.map-btn{
    border-radius: 3px;
}
.mobile-nav{
    display: none;
}
.deliverables-but:hover{
    cursor: pointer;
}

@media only screen and (max-width: 1100px) {
    .title-head{
        font-size: 17px;
    }
}

@media only screen and (max-width: 992px) {
    .title-head{
        font-size: 13px;
    }
}
@media only screen and (max-width: 900px) {
    .title-head{
        font-size: 11px;
    }
}
@media only screen and (max-width: 770px) {
    .logo img{
        height: 4rem;
    }

}
@media only screen and (max-width: 730px) {
    .social,
    .selections{
        display: none;
        visibility:hidden;
    }
    .selections{
        display: none;
        visibility:hidden;
    }
    .social-out {
        right: 5px;
    }
    .mobile-nav{
        display: block;
    }
    .logo{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .mobile-nav{
        position: sticky;
    }

    .menu-mobile-inner{
        border-radius: 50px;
        gap: 10px;
    }    
    .menu-mobile-inner img{
        border-radius: 100%;
        fill: white !important;
        color: aliceblue;
        width: 45px;
        height: 70%;
    }

}
.partner-card{
    padding: 20px;
    background-color: #fafafa;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    height: 150px;
    display: flex;
    justify-content: left;
    align-items: center;
}
.partner-card img{
    aspect-ratio: 4/3;
    object-fit: contain;
}